import Author from "@molecules/Author";
import React from "react";
import styles from "./testimoniesCard.module.scss";

const TestimoniesCard = ({ testimony, name, job, image }) => {
  return (
    <div className={styles.testimoniesCard}>
      <h3 className={styles.testimoniesCard_heading}>
        {/* {t("TESTIMONIES.FERNANDO.TEXT")} */}
        {testimony}
      </h3>
      <Author
        img={image}
        name={
          // t("TESTIMONIES.FERNANDO.NAME")
          name
        }
        job={
          // t("TESTIMONIES.FERNANDO.ROLE")
          job
        }
      />
    </div>
  );
};

export default TestimoniesCard;
