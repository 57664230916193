import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useOnScreen } from "../../../hooks/useOnScreen";
import styles from "./Testimonies.module.scss";
import TadeasKsir from "../../../assets/image/Tadeas-Ksir.webp";
import MaxHarariInvent from "../../../assets/image/Max-Harari-Invent.webp";
import LisaSullivanInvent from "../../../assets/image/Lisa-Sullivan-Invent.webp";
import Fernando_Jansenson from "../../../assets/image/Fernando_Jansenson.webp";
import JurgZeltner from "../../../assets/image/Jurg-Zeltner.webp";
import Amanda_Blanc from "../../../assets/image/Amanda_Blanc.webp";
import PaulinaSalas from "../../../assets/image/Paulina-Salas.webp";
import MuayyadAdili from "../../../assets/image/Muayyad-Adili.webp";
import MarkStevens from "../../../assets/image/Mark-Stevens.webp";
import TestimoniesCard from "@atoms/TestomoniesCard";
import { LazyMotion, m, domAnimation } from "framer-motion";

const hide = { opacity: 0, y: 20 };
const show = { opacity: 1, y: 0 };

const Testimonies = ({ location }) => {
  const [testimoniesRef, isTestimoniesVisible] = useOnScreen({
    threshold: 0.1,
  });
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    if (isTestimoniesVisible) {
      setAnimation(true);
    }
  }, [isTestimoniesVisible]);

  const { t } = useTranslation();

  const usersTestimonies = useMemo(
    () => [
      {
        testimony: t("TESTIMONIES.TADEAS.TEXT"),
        image: TadeasKsir,
        name: t("TESTIMONIES.TADEAS.NAME"),
        job: t("TESTIMONIES.TADEAS.ROLE"),
      },
      {
        testimony: t("TESTIMONIES.FERNANDO.TEXT"),
        image: Fernando_Jansenson,
        name: t("TESTIMONIES.FERNANDO.NAME"),
        job: t("TESTIMONIES.FERNANDO.ROLE"),
      },
      {
        testimony: t("TESTIMONIES.PAULINA.TEXT"),
        image: PaulinaSalas,
        name: t("TESTIMONIES.PAULINA.NAME"),
        job: t("TESTIMONIES.PAULINA.ROLE"),
      },

      {
        testimony: t("TESTIMONIES.MAX.TEXT"),
        image: MaxHarariInvent,
        name: t("TESTIMONIES.MAX.NAME"),
        job: t("TESTIMONIES.MAX.ROLE"),
      },
      {
        testimony: t("TESTIMONIES.JURG.TEXT"),
        image: JurgZeltner,
        name: t("TESTIMONIES.JURG.NAME"),
        job: t("TESTIMONIES.JURG.ROLE"),
      },
      {
        testimony: t("TESTIMONIES.MUAYYAD.TEXT"),
        image: MuayyadAdili,
        name: t("TESTIMONIES.MUAYYAD.NAME"),
        job: t("TESTIMONIES.MUAYYAD.ROLE"),
      },
      {
        testimony: t("TESTIMONIES.LISA.TEXT"),
        image: LisaSullivanInvent,
        name: t("TESTIMONIES.LISA.NAME"),
        job: t("TESTIMONIES.LISA.ROLE"),
      },
      {
        testimony: t("TESTIMONIES.AMANDA.TEXT"),
        image: Amanda_Blanc,
        name: t("TESTIMONIES.AMANDA.NAME"),
        job: t("TESTIMONIES.AMANDA.ROLE"),
      },

      {
        testimony: t("TESTIMONIES.MARK.TEXT"),
        image: MarkStevens,
        name: t("TESTIMONIES.MARK.NAME"),
        job: t("TESTIMONIES.MARK.ROLE"),
      },
    ],
    [t]
  );

  let usertestimoniesServices;

  if (location.pathname === "/services") {
    usertestimoniesServices = [
      usersTestimonies[2],
      usersTestimonies[0],
      usersTestimonies[1],
      usersTestimonies[5],
      usersTestimonies[3],
      usersTestimonies[4],
      usersTestimonies[8],
      usersTestimonies[6],
      usersTestimonies[7],
    ];
  } else if (location.pathname === "/platform") {
    usertestimoniesServices = [
      usersTestimonies[1],
      usersTestimonies[0],
      usersTestimonies[2],
      usersTestimonies[4],
      usersTestimonies[3],
      usersTestimonies[5],
      usersTestimonies[7],
      usersTestimonies[6],
      usersTestimonies[8],
    ];
  } else {
    usertestimoniesServices = [...usersTestimonies];
  }

  return (
    <>
      <section ref={testimoniesRef} className={styles.section}>
        <LazyMotion features={domAnimation}>

        <m.h2
          initial={hide}
          animate={animation ? show : hide}
          transition={{
            type: "spring",
            stiffness: 20,
          }}
          className={styles.section_title}
          >
          {t("TESTIMONIES.TITLE")}
        </m.h2>
        <m.div
          initial={hide}
          animate={animation ? show : hide}
          transition={{
            type: "spring",
            stiffness: 20,            
          }}
          className={`${styles.section_cardContainer}`}
          >
          {usertestimoniesServices.map((item, index) => {
            return (
              <TestimoniesCard
              key={index}
              testimony={item.testimony}
              image={item.image}
              name={item.name}
              job={item.job}
              />
              );
            })}
        </m.div>
        </LazyMotion>
      </section>
    </>
  );
};

export default Testimonies;
