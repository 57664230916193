import React from "react";
import styles from "./author.module.scss";
import PropTypes from "prop-types";

const Author = ({ img, name, job, swiper }) => {
  return (
    <div
      className={styles.dAuthor}
      style={
        swiper
          ? {
              alignItems: "center",
            }
          : undefined
      }
    >
      <div className={styles.imageCropper}>
        <img src={img} alt={name} width={53} height={53} loading="lazy"/>
      </div>
      <div className={styles.ml20}>
        <p className={styles.authorName}>{name}</p>
        <p className={styles.authorJob}>{job}</p>
      </div>
    </div>
  );
};

Author.propTypes = {
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
};

export default Author;
